<script>
import { nextTick } from 'vue'

export default {
  emits: ['pageChange', 'select'],
  props: {
    // 传了就用传进来的，否则就用route.path当做id
    tableId: { type: String, default: '' },
    rowKey: {
      type: String,
      default: 'id',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    defaultColumnWidth: {
      type: Number,
      default: 200,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageState: {
      type: Object,
      default: () => ({ page: 1, pageSize: 10 }),
    },
    tableStyle: {
      type: Object,
      default: () => ({}),
    },
    // 多选
    isSelection: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean || Function,
      default: false,
    },
  },
  computed: {
    selectedKeys() {
      return this.selectedRows?.map((item) => item[this.rowKey])
    },
    aboutSelection() {
      return [this.selectedRows, this.tableData]
    },
  },
  watch: {
    // 为了调用 toggleRowSelection 更新视图
    aboutSelection([selectedRowsValue, tableDataValue]) {
      if (this.isSelection) {
        nextTick().then(() => {
          // selectedRowsValue.forEach((row) => {
          //   const item = this.tableData.find((itm) => itm[this.rowKey] == row[this.rowKey])
          //   if (item) {
          //     this.$refs.elTableRef?.toggleRowSelection(item, true) // 要用tableData里的数据 toggleRowSelection
          //   }
          // })
          this.tableData.map((item) => {
            let flag = selectedRowsValue.some((itm) => itm[this.rowKey] === item[this.rowKey])
            this.$refs.elTableRef?.toggleRowSelection(item, flag) // 要用tableData里的数据 toggleRowSelection
          })
        })
      }
    },
  },
  /**
   * 方法
   */
  methods: {
    handleCurrentChange(v) {
      this.$emit('pageChange', {
        page: v,
        pageSize: this.pageState.pageSize,
      })
    },
    handleSizeChange(v) {
      this.$emit('pageChange', {
        page: 1, // pageSize改变的时候，重新回到第一页
        pageSize: v,
      })
    },
    // 多选
    handleSelect(rows, item) {
      let emitRows = [item]
      let selected
      if (this.selectedKeys.indexOf(item[this.rowKey]) > -1) {
        // 取消
        selected = false
      } else {
        // 勾选
        selected = true
      }
      this.$emit('select', {
        rows: emitRows,
        selected,
      })
    },
    handleSelectAll(rows) {
      // console.log('🚀 ~ ', this.selectedRows.length)
      // console.log('🚀 ~ ', rows.length)
      let selected
      if (rows.length > this.selectedRows.length) {
        selected = true
      } else {
        selected = false
      }
      this.$emit('select', {
        rows: this.tableData,
        selected,
      })
    },
    // handleClickTable: throttle(
    //   function (e) {
    //     e.stopPropagation?.()
    //     const dom = e.target
    //     if (dom?.parentNode?.classList && Array.from(dom.parentNode.classList).indexOf?.('el-checkbox__input') > -1) {
    //       // 点击的是 selection
    //       if (dom.parentNode?.parentNode?.parentNode?.parentNode?.tagName === 'TH') {
    //         // 点击的是 selection all
    //         const classList = Array.from(dom.parentNode.classList)
    //         let selected
    //         if (classList.indexOf('is-checked') > -1) {
    //           // 全部取消
    //           selected = false
    //         } else {
    //           // 全部加上
    //           selected = true
    //         }
    //         console.log('🚀 ~ emit tableData', this.tableData, selected)
    //         nextTick().then(() => {
    //           this.$emit('select', {
    //             rows: this.tableData,
    //             selected,
    //           })
    //         })
    //       }
    //     }
    //   },
    //   500,
    //   { leading: true, trailing: false }
    // ),
  },
  render() {
    const curPath = this.$route.path
    const tableId = this.tableId || `pro_table_id_${curPath}`

    return (
      <div className="my_pro_table_wrap">
        <div className="my_pro_table" id={tableId} style={this.tableStyle}>
          <el-table
            ref="elTableRef"
            class="table_my"
            rowKey={this.rowKey}
            border={true}
            size="small"
            data={this.tableData}
            v-loading={this.loading}
            style={this.tableStyle}
            onSelect={this.handleSelect}
            onSelectAll={this.handleSelectAll}
          >
            {this.isSelection && <el-table-column class-name="selection123" type="selection" width="35" reserveSelection={true} selectable={this.selectable} />}
            {this.columns.map((columnObj, index) => {
              let columnProps = {
                label: columnObj.label,
                prop: columnObj.prop,
                align: 'center',
              }
              if (columnObj.width) columnProps.width = columnObj.width
              if (columnObj.fixed) columnProps.fixed = columnObj.fixed

              return (
                <el-table-column key={columnObj.prop || index} {...columnProps}>
                  {(e) => {
                    const record = e.row
                    if (columnObj.render) {
                      return columnObj.render(record[columnObj.prop || ''], record)
                    }
                    return record[columnObj.prop || '']
                  }}
                </el-table-column>
              )
            })}
          </el-table>

          <div className="pagination_box">
            <div className="total_text">共{this.total}条</div>
            <el-pagination
              class="pro_table_pagination"
              current-page={this.pageState.page}
              page-size={this.pageState.pageSize}
              page-sizes={[10, 20, 50, 100, 200, 300, 400]}
              total={this.total}
              layout="sizes, prev, pager, next, jumper"
              onCurrentChange={this.handleCurrentChange}
              onSizeChange={this.handleSizeChange}
            ></el-pagination>
          </div>
        </div>
      </div>
    )
  },
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
